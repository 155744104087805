<template>
    <div class="comfirm-box">
        <div class="content-box">
            <div class="cart-title">
                <div class="title">
                    商品清单
                </div>
                <div class="warp-box">
                    <ul>
                        <li>
                            <div class="img">
                                <img src="../assets/img/cart-showIcon.png" alt="">
                            </div>
                            <div class="text">购物车</div>
                        </li>
                        <li>
                            <div class="img2">
                                <img src="../assets/img/cart-confirmShowIcon.png" alt="">
                            </div>
                            <div class="text">确认订单</div>
                        </li>
                        <li>
                            <div class="img3">
                                <img src="../assets/img/cart-paymentIcon.png" alt="">
                            </div>
                            <div class="text">支付订单</div>
                        </li>
                        <li>
                            <div class="img4">
                                <img src="../assets/img/cart-successIcon.png" alt="">
                            </div>
                            <div class="text">完成</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="order-content">
                <div class="order-box">
                    <ul class="order-list">
                        <li v-for="(item,index) in goodsList" :key="index">
                            <div class="slide-box">
                                <div class="img">
                                    <img :src="item.logo" alt="">
                                </div>
                                <div class="word">
                                    <div class="word-left">
                                        <div class="word-title">
                                            {{item.courseName}}
                                        </div>
                                        <div class="sub-title">
                                            <div class="sub-one">
                                                课程价格：<span>￥{{item.price}}</span>
                                            </div>
                                            <!-- <div>
                                                购买数量：1
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="word-right">
                                        小计：<span>￥{{item.price}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="order-comfirm">
                    <div>
                        <div class="order-right">
                            <div class="right-top">
                                共{{getTotal.totalNum}}件商品，商品总金额：<span class="top-span">￥{{getTotal.totalPrice}}</span>
                            </div>
                            <div class="right-bottom">
                                应付金额：<span class="bottom-span">￥{{getTotal.totalPrice}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-button">
                        <div class="prev-button">
                            <a href="javascript:;" class="prev-btn" @click="$router.back()">返回购物车</a>
                        </div>
                        <div class="next-button">
                            <a href="javascript:;" class="next-btn" @click="pay">提交订单</a>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'orderConfirm',
    data() {
        return {
            goodsList: [],
        }
    },
    mounted() {
        this.getGoods()
    },
    computed:{
        getTotal:function(){
        // 获取list中select为true的数据
        var goodsList = this.goodsList;
        var totalPrice = 0;
        for (var i = 0; i < goodsList.length; i++) {
            totalPrice += goodsList[i].price
        }
        // 选择产品的件数就是_proList.lenth，总价就是totalPrice
        
        return{totalNum:goodsList.length,totalPrice:totalPrice}
      }
    },
    metaInfo: {
        title: '商品购买确认-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        getGoods() {
            let idList = JSON.parse(sessionStorage.getItem('idList'));
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/list.html',
                data: {
                    courseIds: idList
                }
            }).then((res) => {
                this.goodsList = res.data.result.list;
            })
        },
        pay() {
            let idList = JSON.parse(sessionStorage.getItem('idList'));
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/order/submit_order.html',
                data: {
                    courseIds: idList
                }
            }).then((res) => {
                if(res.data.code == 1000) {
                    this.$message.warning(res.data.msg);
                    this.$router.back()
                } else {
                    if(res.data.result.payStatus == 0) {
                        let orderId = res.data.result.orderId;
                        let totalPrice = res.data.result.total_fee;
                        this.$store.commit('saveCartCount','0');
                        // this.getTime(orderId);
                        sessionStorage.setItem('totalPrice',JSON.stringify(totalPrice));
                        this.$router.push({
                            path: '/orderPay',
                            query: {
                                orderId: orderId
                            }
                        });
                        sessionStorage.removeItem("idList");
                    } else if(res.data.result.payStatus == 1){
                        this.$store.commit('saveCartCount','0');
                        let orderId = res.data.result.orderId;
                        this.$router.push({
                            path: '/orderSuccess',
                            query: {
                                orderId: orderId,
                            }
                        });
                    }
                }
                sessionStorage.removeItem("idList");
            })
            // this.$router.push('/orderPay');
        }
    }
}
</script>

<style lang="scss">
    .comfirm-box {
        padding-top: 83px;
        padding-bottom: 25px;
        width: 100%;
        min-width: 1200px;
        // height: 600px;
        background: #EEEEEE;
        .content-box {
            margin: 0 auto;
            width: 1200px;
            padding-bottom: 80px;
            box-sizing: border-box;
            // height: 587px;
            background: #FFFFFF;
            .cart-title {
                padding-left: 30px;
                padding-right: 30px;
                margin-bottom: 40px;
                display: flex;
                justify-content: space-between;
                height: 118px;
                border-bottom: 1px solid #EEEEEE;
                .title {
                    line-height: 118px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    color: #444444;
                }
                .warp-box {
                    padding-top: 30px;
                    ul {
                        display: flex;
                        li {
                            text-align: center;
                            .img {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img2 {
                                margin-bottom: 10px;
                                width: 94px;
                                height: 36px;
                                // img {
                                //     width: 100%;
                                //     height: 100%;
                                // }
                            }
                            .img3 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img4 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                        }
                    }
                }
            }
            .order-content {
                .order-box {
                    margin-bottom: 36px;
                    .order-list {
                        
                        margin: 0 auto;
                        width: 1140px;
                        // height: 175px;
                        li {
                            padding: 20px 30px;
                            margin-bottom: 40px;
                            box-sizing: border-box;
                            background: #F9F9F9;
                            .slide-box {
                                display: flex;
                                .img {
                                    margin-right: 23px;
                                    width: 215px;
                                    height: 135px;
                                    background: #DCDCDC;
                                }
                                .word {
                                    width: 841px;
                                    padding-top: 16px;
                                    display: flex;
                                    justify-content: space-between;
                                    .word-left {
                                        .word-title {
                                            margin-bottom: 27px;
                                            font-size: 16px;
                                            font-family: Microsoft YaHei;
                                            color: #333333;
                                        }
                                        .sub-title {
                                            margin-left: 12px;
                                            display: flex;
                                            .sub-one {
                                                margin-right: 39px;
                                                span {
                                                    color: #FF5A74;
                                                }
                                            }
                                        }
                                    }
                                    .word-right {
                                        color: #666666;
                                        font-size: 14px;
                                        span {
                                            color: #FF5A74;
                                            font-size: 28px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .order-comfirm {
                    margin: 0 auto;
                    width: 1140px;
                    .order-right {
                        text-align: right;
                        .right-top {
                            margin-bottom: 18px;
                            font-size: 14px;
                            color: #666666;
                            .top-span {
                                margin-left: 50px;
                            }
                        }
                        .right-bottom {
                            padding-left: 101px;
                            margin-bottom: 40px;
                            .bottom-span {
                                margin-left: 22px;
                                font-size: 20px;
                                color: #FF5A74;
                            }
                        }
                    }
                    .order-button {
                        // float: right;
                        margin-left: 833px;
                        display: flex;
                        .prev-button {
                            margin-right: 20px;
                            .prev-btn {
                                display: inline-block;
                                width: 143px;
                                height: 45px;
                                border: 1px solid #DEDEDE;
                                line-height: 45px;
                                font-size: 16px;
                                text-align: center;
                                color: #666666;
                            }
                        }
                        .next-button {
                            .next-btn {
                                display: inline-block;
                                width: 143px;
                                height: 45px;
                                background: #FF5A74;
                                line-height: 45px;
                                font-size: 16px;
                                text-align: center;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
</style>